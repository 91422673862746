import "./style.css"

function BurningQuestion(){
    return(
        <section className="BurningQuestionMain">
            <div className="container-fluid">
                <h3>What are your <span style={{color: "#F88D2A" }}>burning questions?</span></h3>
                <p className="subtitle">Actitude will help you find answers.</p>
                <div className="row mt-5">
                    <div className="col-md-7 col-sm-12">
                        <ul className="burningQuestion me-3">
                            <li className="burningQuestionList">What <span>cause</span> do I want to support?</li>
                            <li className="burningQuestionList"><span>Where</span> in the world?</li>
                            <li className="burningQuestionList">Who or what is my <span>focus</span>?</li>
                            <li className="burningQuestionList"><span>How</span> can I best contribute?</li>
                            <li className="burningQuestionList"><span>Who else</span> is actively engaged in this cause?</li>
                            <li className="burningQuestionList">Am I <span>ready</span> to take action?</li>
                        </ul>
                    </div>
                    <div className="col-md-5 col-sm-12 justify-content-end d-flex">
                        <div className="burningQuestionForm">
                            <p>We guide and support you <br /> for free, whether you’re just <br />  starting out or you simply <br /> need direction.</p>
                            <button className="defaultBtnStyle">TAKE THE NEXT STEP</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BurningQuestion;
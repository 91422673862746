import Logo from "../../assets/images/logo/logo.svg"
import IndividualIcon from "../../assets/images/icons/1.svg";
import CoperateIcon from "../../assets/images/icons/2.svg";
import NgoIcon from "../../assets/images/icons/3.svg";
import SideMenuIcon from "../../assets/images/icons/sidebarIcon.svg";

import "./style.css"
function Header() {
    return(
        <header>
            <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid">
                    <button className="sideMenuBtn">
                        <img src={SideMenuIcon} />
                    </button>
                    <a class="navbar-brand" href="#">
                        <img src={Logo} height={"83px"}/>
                    </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul class="navbar-nav mb-2 mb-lg-0">
                        <li class="nav-item">
                            <button className="headerButton btn1 active">
                                <img src={IndividualIcon} width={"52px"}/>
                                <div className="headerButtonText">
                                    <span>INDIVIDUAL</span>
                                    <span>VOLUNTEERING</span>
                                </div>
                            </button>
                        </li>
                        <li class="nav-item">
                            <button className="headerButton btn2">
                                <img src={CoperateIcon} />
                                <div className="headerButtonText">
                                    <span>CORPORATE EMPLOYEE</span>
                                    <span>VOLUNTEER PROGRAMS</span>
                                </div>
                            </button>
                        </li>
                        <li class="nav-item">
                            <button className="headerButton btn3">
                                <img src={NgoIcon} />
                                <div className="headerButtonText">
                                    <span>NGOS LOOKING</span>
                                    <span>FOR VOLUNTEERS</span>
                                </div>
                            </button>
                        </li>
                    </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}


export default Header;
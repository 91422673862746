import "./style.css"
import AttitudeImage from "../../../assets/images/icons/9.svg";
function HelpConnect(){
    return(
        <section className="HelpConnectMain">
            <div className="container">
                <h3>Let’s help you <span style={{color: "#F88D2A" }}>connect?</span></h3>
                <p className="subtitle">At Actitude, we connect you with the right NGOs, causes, and projects <br /> to make the world a better place. We help match the need for support <br /> with the desire to help, knowing that with a little help and a strong <br /> community, it really is possible to make a difference in our world.</p>

                <div className="text-center mt-5">
                    <img src={AttitudeImage} className="w-75 ps-5 pe-5"/>
                </div>
            </div>
        </section>
    )
}

export default HelpConnect;
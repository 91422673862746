import "./style.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper/modules";
import imgF4 from "../../../assets/images/icons/4.svg";
import imgF6 from "../../../assets/images/icons/8.svg";
import imgF7 from "../../../assets/images/icons/7.svg";
import imgF8 from "../../../assets/images/icons/8.svg";

function PassionateSection(){
    return(
        <section>
            <div className="container-fluid">
                <h3>What causes are you most <br />passionate to <span style={{color: "#F88D2A" }}>take action</span> on?</h3>
                <p className="subtitle">Examples of causes you can support</p>
                <Swiper navigation={true} modules={[Navigation]} className="group-46">
                    <SwiperSlide>
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <figure>
                                        <img src={imgF4} />
                                    </figure>
                                    <h5 class="card-title">
                                        <span>Terrorism</span>
                                    </h5>
                                    <p class="card-text">Terrorism is the use of intentional violence for political or religious purposes. What would it take to stop or greatly reduce terrorism in the world?</p>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div class="card">
                                    <div class="card-body">
                                        <figure>
                                            <img src={imgF6} />
                                        </figure>
                                        <h5 class="card-title">
                                            <span>Energy Consumption (efficiency)</span>
                                        </h5>
                                        <p class="card-text">The efficient use of energy when producing, providing or consuming products & services.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <figure>
                                        <img src={imgF7} />
                                    </figure>
                                    <h5 class="card-title">
                                        <span>Economic <br/> Equality</span>
                                    </h5>
                                    <p class="card-text">Inequality within and among countries is a persistent cause for concern. Despite some positive signs toward reducing inequality in some dimension, strong inequality still persists.</p>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div class="card">
                                    <div class="card-body">
                                        <figure>
                                            <img src={imgF8} />
                                        </figure>
                                        <h5 class="card-title">
                                            <span>Life on the <br/>  land</span>
                                        </h5>
                                        <p class="card-text">Well-being, protection and preservation of ecosystems on land.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <figure>
                                        <img src={imgF4} />
                                    </figure>
                                    <h5 class="card-title">
                                        <span>Terrorism</span>
                                    </h5>
                                    <p class="card-text">Terrorism is the use of intentional violence for political or religious purposes. What would it take to stop or greatly reduce terrorism in the world?</p>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div class="card">
                                    <div class="card-body">
                                        <figure>
                                            <img src={imgF6} />
                                        </figure>
                                        <h5 class="card-title">
                                            <span>Energy Consumption (efficiency)</span>
                                        </h5>
                                        <p class="card-text">The efficient use of energy when producing, providing or consuming products & services.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <figure>
                                        <img src={imgF7} />
                                    </figure>
                                    <h5 class="card-title">
                                        <span>Economic <br/> Equality</span>
                                    </h5>
                                    <p class="card-text">Inequality within and among countries is a persistent cause for concern. Despite some positive signs toward reducing inequality in some dimension, strong inequality still persists.</p>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div class="card">
                                    <div class="card-body">
                                        <figure>
                                            <img src={imgF8} />
                                        </figure>
                                        <h5 class="card-title">
                                            <span>Life on the <br/>  land</span>
                                        </h5>
                                        <p class="card-text">Well-being, protection and preservation of ecosystems on land.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    )
}

export default PassionateSection;
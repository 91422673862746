// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAHWv90ap-P4PaSXqIld9laYzBukXmJD08",
  authDomain: "react-one-page.firebaseapp.com",
  projectId: "react-one-page",
  storageBucket: "react-one-page.appspot.com",
  messagingSenderId: "362681210701",
  appId: "1:362681210701:web:21ce3d221a4ca710990904",
  measurementId: "G-H3D3S9RT9K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const dbFirestore = getFirestore(app);
export default app;

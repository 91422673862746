import "./style.css"
import volunteer from "../../../assets/images/icons/volunteer.svg"
function Volunteer(){
    return(
        <section className="VolunteerMain">
            <div className="container-fluid">
                <div className="row mt-5">
                    <div className="col-md-4 col-sm-12 justify-content-start d-flex">
                        <img src={volunteer} className="w-100"/>
                    </div>
                    <div className="col-md-8 col-sm-12 VolunteerRight d-flex align-items-center justify-content-center">
                        <div>
                            <h3>Why should <span style={{color: "#F88D2A" }}>you</span> <br /> volunteer?</h3>
                            <p>Impactful volunteering is an exciting way to:</p>
                            <ul className="burningQuestion me-3">
                                <li className="burningQuestionList">Help <span>make the world a better place</span></li>
                                <li className="burningQuestionList">Create more meaning and <span>purpose</span> in your life</li>
                                <li className="burningQuestionList">Build <span>deep relationships</span> and friendships while <br /> collaborating with other volunteers​</li>
                                <li className="burningQuestionList">Experience exciting new opportunities for learning.​</li>
                                <li className="burningQuestionList">Deepen your <span>compassion</span>, care, and kindness</li>
                                <li className="burningQuestionList">Increase your <span>well-being</span></li>
                            </ul>
                            <button className="defaultBtnStyle mt-5">TAKE THE NEXT STEP</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default Volunteer;
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import Banner from "./Banner/index"
import BurningQuestion from "./BurningQuestion"
import HelpConnect from "./HelpConnect"
import KnowMore from "./KnowMore"
import MeetVolunteers from "./MeetVolunteers"
import OurVision from "./OurVision"
import PassionateSection from "./Passionate"
import Volunteer from "./Volunteer"

function Home(){
    return(
        <>
            <Header />
            <Banner />
            <PassionateSection />
            <BurningQuestion />
            <HelpConnect />
            <Volunteer />
            <MeetVolunteers />
            <OurVision />
            <KnowMore />
            <Footer />
        </>
    )
}


export default Home
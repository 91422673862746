import Logo from "../../assets/images/logo/logo.svg"
import "./style.css";
function Footer(){
    return(
        <div className="FooterMain">
            <div class="container">
            <h3>About Actitude</h3>
                <p className="para">We help activate and support people to make the world a better place, turning the positive intention to make a difference into impactful, practical action. Actitude is a non-profit organization founded in Copenhagen, Denmark in 2018.</p>
            <footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 border-top">                
                <div class="col-md-7 mb-3">
                <a href="/" class="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
                    <img src={Logo} width={"256px"} />
                </a>
                </div>

                <div class="col mb-3">
                <ul class="nav flex-column">
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">www.actitude.com</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">info@actitude.com</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">+45 35851000</a></li>
                </ul>
                </div>

                <div class="col mb-3">
                <ul class="nav flex-column">
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Rosenørns Allé 44, st. tv.</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">1970 Frederiksberg</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Copenhagen</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Denmark</a></li>
                </ul>
                </div>
            </footer>
            </div>
        </div>
    )
}


export default Footer
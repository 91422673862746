import "./style.css"
import AttitudeImage from "../../../assets/images/icons/9.svg";
function KnowMore(){
    return(
        <section className="KnowMoreMain">
            <div className="container">
                <h3>Want to know more?</h3>
                <p className="subtitle">Sign up for our newsletter here.</p>

                <form className="m-auto w-50">
                    <div>
                        <label>Company</label>
                        <input type="text" placeholder="(optional)" className="w-75" />
                    </div>
                    <div>
                        <label>Name</label>
                        <input type="text" placeholder="(optional)" className="w-75" />
                    </div>
                    <div>
                        <label>Email</label>
                        <input type="text" placeholder="(optional)" className="w-75" />
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            {/* asdasda */}
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <button>SIGN UP</button>
                        </div>
                    </div>
                </form>
            </div>  
        </section>
    )
}

export default KnowMore;
import Carousel from 'react-bootstrap/Carousel';
import BannerSlide1 from "../../../assets/images/Banner/Banner1.png";
import "./style.css"

function Banner(){
    return(
    <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={BannerSlide1}
        //   height={"769px"}
          alt="First slide"
        />
        <Carousel.Caption>
          <p>There are so many opportunities <br />to do good in this world...</p>
          <h5>What difference do <br /> <span style={{color: "#F88D2A"}}>you</span> want to make?</h5>
          <div className="carousel-caption-bottom">
            <small>You want to do good.<br />Let’s help you succeed.</small>
            <button className="defaultBtnStyle">
            TAKE THE NEXT STEP
            </button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={BannerSlide1}
        //   height={"769px"}
          alt="First slide"
        />
        <Carousel.Caption>
          <p>There are so many opportunities <br />to do good in this world...</p>
          <h5>What difference do <br /> <span style={{color: "#F88D2A"}}>you</span> want to make?</h5>
          <div className="carousel-caption-bottom">
            <small>You want to do good.<br />Let’s help you succeed.</small>
            <button className="defaultBtnStyle">
            TAKE THE NEXT STEP
            </button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={BannerSlide1}
        //   height={"769px"}
          alt="First slide"
        />
        <Carousel.Caption>
          <p>There are so many opportunities <br />to do good in this world...</p>
          <h5>What difference do <br /> <span style={{color: "#F88D2A"}}>you</span> want to make?</h5>
          <div className="carousel-caption-bottom">
            <small>You want to do good.<br />Let’s help you succeed.</small>
            <button className="defaultBtnStyle">
            TAKE THE NEXT STEP
            </button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src={BannerSlide1}
          height={"769px"}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={BannerSlide1}
          height={"769px"}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
    )
}


export default Banner;
import "./style.css";

function OurVision(){
    return(
        <section className="OurVisionMain">
            <div className="container">
                <div className="OurVisionMainInner">
                    <div className="row">
                        <div className="col-md-7 col-sm-12 OurVisionLeft">
                            <div className="OurVisionLeftInner">
                                <p>Vision without action is merely a dream Action without vision just passes the time Vision with action can change the world.</p>
                                <div className="ownerName">
                                    <span></span>
                                    <span>Joel L. Barker</span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 OurVisionRight">
                            <div className="burningQuestionForm">
                                <h5>Take Action</h5>
                                <p>Are you ready to make <br /> a difference? We’re <br /> here to support you.</p>
                                <button className="defaultBtnStyle">TAKE THE NEXT STEP</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurVision;
import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Page1 from "../Page1";
// import Page2 from "../Page21";
import Page3 from "../Page3";
import Page4 from "../Page4";
import Page5 from "../Page5";
import Login from "../auth/Login";
import Register from "../auth/Register";
import Profile from "../auth/Profile";
import Home from "../Pages/Home"
// import Home from "../Pages/app/Home";
// import Login from "../Pages/auth/Login";

export default function ReactDOM() {
  //   const isToken = useSelector((state) => state.login.data);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" caseSensitive={false} element={<Home />} />
        <Route path="/old" caseSensitive={false} element={<Page1 />} />
        {/* <Route path="/page2" caseSensitive={false} element={<Page2 />} />
        <Route path="/page3" caseSensitive={false} element={<Page3 />} />
        <Route path="/page4" caseSensitive={false} element={<Page4 />} />
        <Route path="/page5" caseSensitive={false} element={<Page5 />} /> */}

        <Route path="/login" caseSensitive={false} element={<Login />} />
        <Route path="/signup" caseSensitive={false} element={<Register />} />
        <Route path="/profile" caseSensitive={false} element={<Profile />} />
      </Routes>
    </BrowserRouter>
  );
}

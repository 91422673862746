import "./style.css"
import AttitudeImage from "../../../assets/images/icons/9.svg";
import user1 from "../../../assets/images/team/1.svg";
import user2 from "../../../assets/images/team/2.svg";
import user3 from "../../../assets/images/team/3.svg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";

function MeetVolunteers(){
    return(
        <section className="MeetVolunteersMain">
            <div className="container">
                <h3>Meet our Actitude <span style={{color: "#F88D2A" }}>volunteers?</span></h3>
                <p className="subtitle">Meet a few of our volunteers, activated and engaged <br /> in the causes they’re most passionate about ...</p>
                <Swiper navigation={true} modules={[Navigation]} className="group-19">
                    <SwiperSlide>
                        <div className="actitude-volunteers row">
                        <div className="col-md-4 col-sm-12">
                            <div className="frame-10">
                                <img className="ellipse-5" src={user1} />
                                <div className="frame-11">
                                <div className="frame-12">
                                    <div className="text-wrapper-16">Ben</div>
                                    <img className="line-2" src="img/line-14.svg" />
                                    <p className="caring-for-homeless">
                                    Caring For Homeless People <br />
                                    in Hong Kong, S.a.r. China
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="frame-10">
                                <img className="ellipse-5" src={user2} />
                                <div className="frame-11">
                                <div className="frame-12">
                                    <div className="text-wrapper-16">Mattie</div>
                                    <img className="line-2" src="img/line-14.svg" />
                                    <p className="caring-for-homeless">
                                    Caring For Homeless People <br />
                                    in Hong Kong, S.a.r. China
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="frame-10">
                                <img className="ellipse-5" src={user3} />
                                <div className="frame-11">
                                <div className="frame-12">
                                    <div className="text-wrapper-16">Sarah</div>
                                    <img className="line-2" src="img/line-14.svg" />
                                    <p className="caring-for-homeless">
                                    Caring For Homeless People <br />
                                    in Hong Kong, S.a.r. China
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="actitude-volunteers row">
                        <div className="col-md-4 col-sm-12">
                            <div className="frame-10">
                                <img className="ellipse-5" src={user1} />
                                <div className="frame-11">
                                <div className="frame-12">
                                    <div className="text-wrapper-16">Ben</div>
                                    <img className="line-2" src="img/line-14.svg" />
                                    <p className="caring-for-homeless">
                                    Caring For Homeless People <br />
                                    in Hong Kong, S.a.r. China
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="frame-10">
                                <img className="ellipse-5" src={user2} />
                                <div className="frame-11">
                                <div className="frame-12">
                                    <div className="text-wrapper-16">Mattie</div>
                                    <img className="line-2" src="img/line-14.svg" />
                                    <p className="caring-for-homeless">
                                    Caring For Homeless People <br />
                                    in Hong Kong, S.a.r. China
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="frame-10">
                                <img className="ellipse-5" src={user3} />
                                <div className="frame-11">
                                <div className="frame-12">
                                    <div className="text-wrapper-16">Sarah</div>
                                    <img className="line-2" src="img/line-14.svg" />
                                    <p className="caring-for-homeless">
                                    Caring For Homeless People <br />
                                    in Hong Kong, S.a.r. China
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </SwiperSlide>
          </Swiper>
            </div>
        </section>
    )
}

export default MeetVolunteers;
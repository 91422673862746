import logo from "../logo.svg";
import logo1 from "../assets/images/logo/logo.svg";
import imgF from "../assets/images/icons/1.svg";
import imgF2 from "../assets/images/icons/2.svg";
import imgF3 from "../assets/images/icons/3.svg";
import imgF4 from "../assets/images/icons/4.svg";

import imgF6 from "./img/icons-2.png";
import imgF7 from "../assets/images/icons/7.svg";
import imgF8 from "../assets/images/icons/8.svg";
import heartSVG from "../assets/images/icons/path78.svg";
import heartSVG1 from "../assets/images/icons/path78 (1).svg";
import sideBar from "../assets/images/logo/sideBar.svg";
import imgFMaskGroupPage2 from "../assets/images/Banner/bW.svg";
import imgF9 from "../assets/images/icons/9.svg";

import imgF9s from "../assets/images/Banner/uniq.svg";
import imgFObj from "../assets/images/icons/ObjSvg.svg";
import imgFHeart from "../assets/images/icons/heart.png";

import imgFMaskGroup from "./img/mask-group.png";
import imgFMaskGroupMobile from "../assets/images//Banner/image 14 (2).svg";
import imgFMaskGroupMobileShape from "../assets/images//Banner/Rectangle 39968.svg";
import imgFMaskGroupMobileShapeShade from "./img/group-1597879790.png";
import topSVG from "../assets/images//Banner/top.svg";
import bottomSVG from "../assets/images//Banner/bottom.svg";

import user1 from "../assets/images/team/1.svg";
import user2 from "../assets/images/team/2.svg";
import user3 from "../assets/images/team/3.svg";
import banner from "../assets/images/Banner/banner.svg";
import heartGP from "../assets/images/icons/heartGP.svg";
import twf12 from "./img/line-12.svg";
import twf13 from "./img/line-13.svg";
import { useNavigate } from "react-router";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";

// import "../App.css";
// import "../Css/style.css";
import { isMobile } from "react-device-detect";
import "./style.css";
import "./styleguide.css";
import "./globals.css";
import flyH from "./img/mask-group-1.png";
import arrowLeft from "./img/arrow-left-circle-1.svg";
import arrowRight from "./img/arrow-left-circle.svg";
import objss from "./img/objects.svg";
import { useState } from "react";

function Page1() {
  const navigate = useNavigate();
  const [currentIndex, setIndex] = useState(0);

  const handleMouseEvent = (e) => {
    e.persist();
    e.target.style.backgroundColor = "orange";

    setTimeout(() => {
      e.target.style.backgroundColor = "";
    }, 1000);
  };

  return (
    <div className="kennedy-redo">
      <div className="div">
        <div className="overlap">
          <div className="group">
            <div className="overlap-group">
              <div className="rectangle"></div>
              <div className="rectangle-2"></div>
              <img className="mask-group" src={flyH} />
            </div>
          </div>
          <div className="group-2">
            <div className="group-wrapper">
              <div className="div-wrapper">
                <div className="group-wrapper">
                  <div className="group-3">
                    <p className="don-t-waste-time-on">
                      Vision without action is merely a dream Action without
                      vision just passes the time Vision with action can change
                      the world.
                    </p>
                    <div className="frame-wrapper">
                      <div className="frame">
                        <img className="line" src={twf12} />
                        <div className="frame-2">
                          <div className="jaquon-hart-wrapper">
                            <div className="jaquon-hart">Joel L. Barker</div>
                          </div>
                        </div>
                        <img className="line" src={twf13} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-4">
            <div className="frame-3">
              <div className="frame-4">
                <div className="text-wrapper">Take Action</div>
                <p className="p">
                  Are you ready to make a difference? We’re here to support you.
                </p>
              </div>
              <div className="frame-5">
                <div className="text-wrapper-2">TAKE THE NEXT STEP</div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-2">
          <p className="what-are-your">
            <span className="span">What are your </span>{" "}
            <span className="text-wrapper-3">burning questions?</span>
          </p>
          <p className="text-wrapper-4">Actitude will help you find answers.</p>
          <div className="frame-6">
            <p className="what-cause-do-i-want">
              <span className="text-wrapper-5">What </span>
              <span className="text-wrapper-6">cause</span>
              <span className="text-wrapper-5"> do I want to support?</span>
            </p>
            <p className="div-2">
              <span className="text-wrapper-6">Where</span>{" "}
              <span className="text-wrapper-7"> in the world?</span>
            </p>
            <p className="div-3">
              <span className="text-wrapper-7">Who or what is my </span>
              <span className="text-wrapper-6">focus</span>
              <span className="text-wrapper-7">?</span>
            </p>
            <p className="div-2">
              <span className="text-wrapper-6">How</span>{" "}
              <span className="text-wrapper-7"> can I best contribute?</span>
            </p>
            <p className="div-2">
              <span className="text-wrapper-6">Who else</span>
              <span className="text-wrapper-7">
                {" "}
                is actively engaged in this cause?
              </span>
            </p>
            <p className="div-3">
              <span className="text-wrapper-7">Am I </span>
              <span className="text-wrapper-6">ready</span>
              <span className="text-wrapper-7"> to take action?</span>
            </p>
          </div>
          <img className="img" src={imgFMaskGroupMobileShapeShade} />
        </div>
        <div className="overlap-3">
          <img
            className="mask-group-2"
            src={
              currentIndex === 0
                ? imgFMaskGroup
                : currentIndex === 1
                ? imgFMaskGroupPage2
                : currentIndex === 2
                ? imgFMaskGroup
                : currentIndex === 3
                ? imgFMaskGroupPage2
                : imgFMaskGroup
            }
            r
          />
          <p className="there-are-so-many">
            There are so many opportunities
            <br />
            to do good in this world...
          </p>
          <p className="what-difference-do">
            <span className="text-wrapper-8">What difference do </span>
            <br />
            <span className="text-wrapper-3">you</span>

            <span className="text-wrapper-8"> want to make?</span>
          </p>
          <div className="navbar">
            <div className="group-5">
              <div className="frame-7">
                <div className="frame-8">
                  <div className="group-6">
                    <div className="group-7">
                      <div className="rectangle-3"></div>
                      <div className="rectangle-4"></div>
                      <div className="rectangle-5"></div>
                    </div>
                  </div>
                  <img className="horizontal-logo" src={logo1} />
                </div>
                <div className="frame-9">
                  <div className="individual">
                    <div className="overlap-group-wrapper">
                      <div className="overlap-group-2">
                        <div className="group-8">
                          <div className="text-wrapper-9">INDIVIDUAL</div>
                          <div className="text-wrapper-10">VOLUNTEERING</div>
                        </div>
                        <img className="group-9" src={imgF} />
                      </div>
                    </div>
                  </div>
                  <div className="corporate-employee">
                    <div className="group-10">
                      <div className="overlap-group-3">
                        <div className="group-11">
                          <div className="group-12">
                            <div className="text-wrapper-11">
                              CORPORATE EMPLOYEE
                            </div>
                            <div className="text-wrapper-12">
                              VOLUNTEER PROGRAMS
                            </div>
                          </div>
                        </div>
                        <img className="group-13" src={imgF2} />
                      </div>
                    </div>
                  </div>
                  <div className="ngos-looking-for">
                    <div className="group-14">
                      <div className="group-15">
                        <div className="text-wrapper-13">NGOS LOOKING</div>
                        <div className="text-wrapper-14">FOR VOLUNTEERS</div>
                      </div>
                      <img className="group-16" src={imgF3} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flexcontainer">
            <p className="text">
              <span className="text-wrapper-15">
                You want to do good. <br />
              </span>
            </p>
            <p className="text">
              <span className="text-wrapper-15">Let’s help you succeed.</span>
            </p>
          </div>
          <div className="TAKE-THE-NEXT-STEP-wrapper">
            <div className="TAKE-THE-NEXT-STEP">Take The Next Step</div>
          </div>
          <div className="group-17">
            <div
              className={"ellipse"}
              onClick={() => setIndex(0)}
              style={{
                backgroundColor:
                  currentIndex === 0
                    ? "var(--variable-collection-WHITE)"
                    : "#ffffff66",
              }}
            ></div>
            <div
              className={"ellipse-2"}
              onClick={() => setIndex(1)}
              style={{
                backgroundColor:
                  currentIndex === 1
                    ? "var(--variable-collection-WHITE)"
                    : "#ffffff66",
              }}
            ></div>
            <div
              className="ellipse-3"
              onClick={() => setIndex(2)}
              style={{
                backgroundColor:
                  currentIndex === 2
                    ? "var(--variable-collection-WHITE)"
                    : "#ffffff66",
              }}
            ></div>
            <div
              className="ellipse-4"
              onClick={() => setIndex(3)}
              style={{
                backgroundColor:
                  currentIndex === 3
                    ? "var(--variable-collection-WHITE)"
                    : "#ffffff66",
              }}
            >
              {" "}
            </div>
          </div>
        </div>
        <div className="group-18">
          {" "}
          <p className="meet-our-actitude">
            <span className="span">Meet our Actitude </span>{" "}
            <span className="text-wrapper-3">volunteers</span>
          </p>
          <p className="meet-a-few-of-our">
            Meet a few of our volunteers, activated and engaged
            <br />
            in the causes they’re most passionate about ...
          </p>
          <Swiper navigation={true} modules={[Navigation]} className="group-19">
            <SwiperSlide>
              <div className="">
                <div className="actitude-volunteers">
                  <div className="frame-10">
                    <img className="ellipse-5" src={user1} />
                    <div className="frame-11">
                      <div className="frame-12">
                        <div className="text-wrapper-16">Ben</div>
                        <img className="line-2" src="img/line-14.svg" />
                        <p className="caring-for-homeless">
                          Caring For Homeless People <br />
                          in Hong Kong, S.a.r. China
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-20">
                  <div className="frame-10">
                    <img className="ellipse-5" src={user2} />
                    <div className="frame-11">
                      <div className="frame-12">
                        <div className="text-wrapper-16">Mattie</div>
                        <img className="line-2" src="img/line-14.svg" />
                        <p className="caring-for-homeless">
                          Caring For Homeless People <br />
                          in Hong Kong, S.a.r. China
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-21">
                  <div className="frame-13">
                    <img className="ellipse-5" src={user3} />
                    <div className="frame-11">
                      <div className="frame-12">
                        <div className="text-wrapper-16">Sarah</div>
                        <img className="line-2" src="img/line-14.svg" />
                        <p className="caring-for-homeless">
                          Caring For Homeless People <br />
                          in Hong Kong, S.a.r. China
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <div className="actitude-volunteers">
                  <div className="frame-10">
                    <img className="ellipse-5" src={user1} />
                    <div className="frame-11">
                      <div className="frame-12">
                        <div className="text-wrapper-16">Ben</div>
                        <img className="line-2" src="img/line-14.svg" />
                        <p className="caring-for-homeless">
                          Caring For Homeless People <br />
                          in Hong Kong, S.a.r. China
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-20">
                  <div className="frame-10">
                    <img className="ellipse-5" src={user2} />
                    <div className="frame-11">
                      <div className="frame-12">
                        <div className="text-wrapper-16">Mattie</div>
                        <img className="line-2" src="img/line-14.svg" />
                        <p className="caring-for-homeless">
                          Caring For Homeless People <br />
                          in Hong Kong, S.a.r. China
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-21">
                  <div className="frame-13">
                    <img className="ellipse-5" src={user3} />
                    <div className="frame-11">
                      <div className="frame-12">
                        <div className="text-wrapper-16">Sarah</div>
                        <img className="line-2" src="img/line-14.svg" />
                        <p className="caring-for-homeless">
                          Caring For Homeless People <br />
                          in Hong Kong, S.a.r. China
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="overlap-4">
          <div className="frame-14">
            <p className="let-s-help-you">
              <span className="span">Let’s help you </span>{" "}
              <span className="text-wrapper-3">connect</span>
            </p>
            <p className="text-wrapper-17">
              At Actitude, we connect you with the right NGOs, causes, and
              projects to make the world a better place. We help match the need
              for support with the desire to help, knowing that with a little
              help and a strong community, it really is possible to make a
              difference in our world.
            </p>
          </div>
          <div className="group-22">
            <div className="overlap-5">
              <img
                className=""
                src={imgF9}
                style={{ width: "700px", height: "500px" }}
              />
            </div>
          </div>
        </div>
        <p className="why-should-you">
          <span className="span">Why should</span>
          <span className="span">&nbsp;</span>
          <span className="text-wrapper-3">you</span>
          <br />
          <span className="span">&nbsp;</span>
          <span className="span volunteerCls">
            volunteer?​
            <br />
          </span>
        </p>
        <div className="overlap-22">
          <p className="text-wrapper-21">
            Impactful volunteering is an exciting way to:
          </p>
          <div className="flexcontainer-2">
            <p className="">
              <span className="text-wrapper-22">
                ​<br />
              </span>
            </p>
            <p className="text-2">
              <span className="text-wrapper-22">Help </span>
              <span className="text-wrapper-23">
                make the world a better place
                <br />
              </span>
            </p>
            <p className="text-2">
              <span className="text-wrapper-22">Create more </span>
              <span className="text-wrapper-24">meaning</span>
              <span className="text-wrapper-22"> and </span>
              <span className="text-wrapper-23">purpose</span>
              <span className="text-wrapper-22">
                {" "}
                in your life​
                <br />
              </span>
            </p>
            <p className="text-2">
              <span className="text-wrapper-22">Build </span>
              <span className="text-wrapper-23">deep relationships</span>
              <span className="text-wrapper-22">
                {" "}
                and friendships while collaborating with other volunteers​
                <br />
              </span>
            </p>
            <p className="text-2">
              <span className="text-wrapper-22">Experience exciting </span>
              <span className="text-wrapper-24">new opportunities</span>
              <span className="text-wrapper-22">
                {" "}
                for learning.​
                <br />
              </span>
            </p>
            <p className="text-2">
              <span className="text-wrapper-22">Deepen your </span>
              <span className="text-wrapper-23">compassion</span>
              <span className="text-wrapper-22">
                , care, and kindness
                <br />
              </span>
            </p>
            <p className="text-2">
              <span className="text-wrapper-22">Increase your </span>
              <span className="text-wrapper-23">well-being</span>
              <span className="text-wrapper-22">​</span>
            </p>
          </div>
          <div className="frame-19">
            <div className="text-wrapper-2">TAKE THE NEXT STEP</div>
          </div>
        </div>
        <img className="rectangle-6" src="img/line-14-3.png" />
        <div className="frame-20">
          <p className="what-causes-are-you">
            <span className="span">What causes are you most passionate to</span>
            <span className="text-wrapper-3"> take action </span>
            <span className="span">on?</span>
          </p>
          <p className="text-wrapper-25">Examples of causes you can support</p>
        </div>

        <Swiper navigation={true} modules={[Navigation]} className="group-46">
          <SwiperSlide>
            <div className="">
              <div className="frame-21">
                <div className="rectangle-7"></div>
                <div className="frame-22">
                  <img className="icons" src={imgF4} />
                  <div className="frame-23">
                    <div className="text-wrapper-26">Terrorism</div>
                    <p className="terrorism-is-THE-USE">
                      <span className="text-wrapper-27">terrorism </span>
                      <span className="text-wrapper-27">
                        is THE USE OF INTENTIONAL VIOLENCE FOR POLITICAL OR
                        RELIGIOUS PURPOSES. WHAT WOULD IT TAKE TO STOP OR
                        GREATLY REDUCE TERRORISM IN THE WORLD?
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="frame-24">
                <div className="rectangle-8"></div>
                <div className="frame-25">
                  <img className="icons" src={imgF6} />
                  <div className="frame-23">
                    <div className="terrorism">
                      Energy consumption
                      <br />
                      (efficiency)
                    </div>
                    <p className="terrorism-is-THE-USE">
                      The Efficient Use Of Energy When Producing, Providing Or
                      Consuming Products &amp; Services.
                    </p>
                  </div>
                </div>
              </div>
              <div className="frame-26">
                <div className="rectangle-7"></div>
                <div className="frame-22">
                  <img className="icons-2" src={imgF7} />
                  <div className="frame-23">
                    <div className="text-wrapper-26">
                      Economic
                      <br />
                      equality
                    </div>
                    <p className="terrorism-is-THE-USE-2">
                      Inequality Within And Among Countries Is A Persistent
                      Cause For Concern. Despite Some Positive Signs Toward
                      Reducing Inequality In Some Dimension, Strong Inequality
                      Still Persists.
                    </p>
                  </div>
                </div>
              </div>
              <div className="frame-27">
                <div className="rectangle-7"></div>
                <div className="frame-22">
                  <img className="icons" src={imgF8} />
                  <div className="frame-23">
                    <div className="text-wrapper-26">
                      Life On The
                      <br />
                      Land
                    </div>
                    <p className="terrorism-is-THE-USE">
                      Well- Being, Protection And Preservation Of <br />
                      ecosystems On Land.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="">
              <div className="frame-21">
                <div className="rectangle-7"></div>
                <div className="frame-22">
                  <img className="icons" src={imgF4} />
                  <div className="frame-23">
                    <div className="text-wrapper-26">Terrorism</div>
                    <p className="terrorism-is-THE-USE">
                      <span className="text-wrapper-27">terrorism </span>
                      <span className="text-wrapper-27">
                        is THE USE OF INTENTIONAL VIOLENCE FOR POLITICAL OR
                        RELIGIOUS PURPOSES. WHAT WOULD IT TAKE TO STOP OR
                        GREATLY REDUCE TERRORISM IN THE WORLD?
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="frame-24">
                <div className="rectangle-8"></div>
                <div className="frame-25">
                  <img className="icons" src={imgF6} />
                  <div className="frame-23">
                    <div className="terrorism">
                      Energy consumption
                      <br />
                      (efficiency)
                    </div>
                    <p className="terrorism-is-THE-USE">
                      The Efficient Use Of Energy When Producing, Providing Or
                      Consuming Products &amp; Services.
                    </p>
                  </div>
                </div>
              </div>
              <div className="frame-26">
                <div className="rectangle-7"></div>
                <div className="frame-22">
                  <img className="icons-2" src={imgF7} />
                  <div className="frame-23">
                    <div className="text-wrapper-26">
                      Economic
                      <br />
                      equality
                    </div>
                    <p className="terrorism-is-THE-USE-2">
                      Inequality Within And Among Countries Is A Persistent
                      Cause For Concern. Despite Some Positive Signs Toward
                      Reducing Inequality In Some Dimension, Strong Inequality
                      Still Persists.
                    </p>
                  </div>
                </div>
              </div>
              <div className="frame-27">
                <div className="rectangle-7"></div>
                <div className="frame-22">
                  <img className="icons" src={imgF8} />
                  <div className="frame-23">
                    <div className="text-wrapper-26">
                      Life On The
                      <br />
                      Land
                    </div>
                    <p className="terrorism-is-THE-USE">
                      Well- Being, Protection And Preservation Of <br />
                      ecosystems On Land.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="rectangle-9"></div>
        <div className="overlap-25">
          <div className="group-47">
            <div className="group-48">
              <div className="group-49">
                <div className="element-actitude-wrapper">
                  <div className="element-actitude">© 2018-2023 Actitude.</div>
                </div>
              </div>
              <div className="group-50">
                <div className="frame-28">
                  <div className="text-wrapper-28">About Actitude</div>
                  <p className="text-wrapper-29">
                    We help activate and support people to make the world a
                    better place, turning the positive intention to make a
                    difference into impactful, practical action. Actitude is a
                    non-profit organization founded in Copenhagen, Denmark in
                    2018.
                  </p>
                  <img className="line-3" src="img/line-10.svg" />
                  <div className="group-51">
                    <div className="group-52">
                      <div className="text-wrapper-30">1970 Frederiksberg</div>
                      <div className="text-wrapper-31">Copenhagen</div>
                      <div className="text-wrapper-32">Denmark</div>
                      <div className="group-53">
                        <p className="text-wrapper-33">
                          Rosenørns Allé 44, st. tv.
                        </p>
                      </div>
                    </div>
                    <div className="group-54">
                      <div className="text-wrapper-34">www.actitude.com</div>
                      <div className="text-wrapper-35">info@actitude.com</div>
                      <div className="text-wrapper-36">+45 35851000</div>
                    </div>
                    <img className="horizontal-logo-2" src={logo1} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="rectangle-10"></div>
          </footer>
        </div>
        <div className="group-55">
          <div className="overlap-26">
            <div className="text-wrapper-37">SIGN UP</div>
            <div className="group-56">
              <div className="input-fields">
                <div className="overlap-group-5">
                  <div className="error-message">Name</div>
                  <div className="box"></div>
                  <div className="key-field">(optional)</div>
                  <div className="value-main">
                    <img className="start-cursor" src="img/line-14-3.png" />
                    <div className="value-masked">
                      <img className="mask-layer" src="img/mask-layer.png" />
                    </div>
                    <img className="end-cursor" src="img/line-14-3.png" />
                  </div>
                </div>
              </div>
              <div className="text-wrapper-38">Name</div>
            </div>
            <div className="group-57">
              <div className="input-fields">
                <div className="overlap-group-5">
                  <div className="error-message">Name</div>
                  <div className="box"></div>
                  <div className="key-field"></div>
                  <div className="value-main">
                    <img className="start-cursor-2" src="img/line-14-3.png" />
                    <div className="value-masked">
                      <img className="mask-layer" src="img/mask-layer.png" />
                    </div>
                    <img className="end-cursor-2" src="img/end-cursor.png" />
                  </div>
                </div>
              </div>
              <div className="text-wrapper-39">Email</div>
            </div>
          </div>
          <div className="group-58">
            <div className="text-wrapper-40">Company</div>
            <div className="input-fields">
              <div className="overlap-group-5">
                <div className="error-message">Name</div>
                <div className="box"></div>
                <div className="key-field">(optional)</div>
                <div className="value-main">
                  <img className="start-cursor-3" src="img/line-14-3.png" />
                  <div className="value-masked">
                    <img className="mask-layer" src="img/mask-layer.png" />
                  </div>
                  <img className="end-cursor-3" src="img/line-14-3.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="text-wrapper-41">Want to know more?</div>
          <p className="text-wrapper-42">Sign up for our newsletter here.</p>
          <div className="frame-29">
            <div className="text-wrapper-43">SIGN UP</div>
          </div>
        </div>
        <img className="OBJECTS" src={objss} />
      </div>
    </div>
  );
}
export default Page1;
